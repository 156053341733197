// import { useContext, useState } from "react"
// import { ChatContext } from "../context/ChatContext"
// import { UserContext } from "../context/UserContext"

export const NewChatButton: React.FC <{onClick: () => void}>= ({onClick}) => {
    // const [message,setMessage] = useState("")
    // const { sendMessage,targetUserId } = useContext(ChatContext)
    // const { user } = useContext(UserContext)
    // const { roomId } = useContext(RoomContext)
    
    return (
        <div className="fixed bottom-0 left-0 mb-4 ml-3">
            <button className="shadow-main flex justify-center items-center ml-2 p-3 hover:bg-sky-500 button-primary rounded-full text-white uppercase font font-semibold" onClick={onClick} >
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square w-6 h-6" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                </svg>
            </button>
        </div>
    )
}