import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { addHistoryAction, toggleChatAction } from "../reducers/chatActions";
import { chatReducer, ChatState } from "../reducers/chatReducer";
import { addMessageAction } from "../reducers/chatActions";
import { addConversationsAction } from "../reducers/conversationActions";
import { conversationReducer, ConversationState } from "../reducers/conversationReducer";
import { userReducer, UserState } from "../reducers/userReducer";
import { IMessage } from "../types/chat";
import { ws } from "../ws";
import { addUsersAction } from "../reducers/userActions";
import { UserContext } from "./UserContext";
// import collect from "collect.js";

interface Props {
    children: React.ReactNode;
}

interface ChatsValue {
    chats:ChatState
    sendMessage: (message:string, roomId:string, author:string, conversation_id:number, attachment:object)=>void
    sendFile: (message:string, roomId:string, author:string, conversation_id:number, attachment:object)=>void
    sendFileCamera: (message:string, roomId:string, author:string, conversation_id:number, attachment:object)=>void
    sendFileCameraVideo: (message:string, roomId:string, author:string, conversation_id:number, attachment:object)=>void
    toggleChat: () => void,
    conversations:ConversationState,
    users:UserState,
    roomStatus:boolean,
    joinRoom: (channelId:string,userId:string, userName:string, conversationId:any) => void,
    targetUserId: string,
    conversationId: any,
    newChat:boolean,
    createNewChat: () => void,
    newConversation: (user:any,senderUser:number) => void,
    disableChat:boolean,
    broadcast: any,
}

export const ChatContext = createContext<ChatsValue>({
    chats: {
        messages:[ 
        ],
        isChatOpen: false,
    },
    sendMessage: (message:string, roomId:string, author:string, conversation_id:number, attachment:object)=>{},
    sendFile: (message:string, roomId:string, author:string, conversation_id:number, attachment:object) => {},
    sendFileCamera: (message:string, roomId:string, author:string, conversation_id:number, attachment:object) => {},
    sendFileCameraVideo: (message:string, roomId:string, author:string, conversation_id:number, attachment:object) => {},
    toggleChat:()=>{}, 
    conversations:{
        conversations:[],
    },
    users:{
        users:[],
    },
    roomStatus:false,
    joinRoom: (channelId:string,userId:string, userName:string, conversationId:number) => {},
    targetUserId:"",
    conversationId:{},
    newChat:false,
    createNewChat: () => {},
    newConversation: (user:any,senderUser:number) => {},
    disableChat:true,
    broadcast: {},
});


export const ChatProvider : React.FC<Props> = ({children})=>{
    const [chats, chatDispatch] = useReducer(chatReducer, {
        messages:[
        ],
        isChatOpen: false
    })
    const [targetUserId, setTargetUserId ] = useState<string>("")
    const [conversationId, setConversationId ] = useState<any>({})
    const [conversations, coversationDispatch] = useReducer(conversationReducer,{conversations:[]})
    const [users, usersDispatch] = useReducer(userReducer,{users:[]})
    const [roomStatus, setRoomStatus] = useState<boolean>(false)
    const [newChat, setNewChat] = useState<boolean>(false)
    const {user} = useContext(UserContext) 
    const [disableChat,setDisableChat] = useState<boolean>(true)
    const [broadcast,setBroadcast] = useState<any>({})


    const fetchConversations = (conversation:any) =>{
        coversationDispatch(addConversationsAction(conversation.resources))
    }

    const sendMessage = (message:string, roomId:string, author:string, conversation_id:any, attachment: any) => {
        if (message || attachment.data) {
            const ChatContainer = document.getElementById('chat-container')
            setTimeout(() => {
                ChatContainer?.scrollTo({
                    top: ChatContainer?.scrollHeight,
                    behavior: "smooth"
                  });
              }, 100);
            let parts
            let ext
            let filename
            // let path
            if (attachment.data !== undefined){
                parts = attachment.name.split('.')
                ext = parts[parts.length - 1]
                filename = Date.now() + '.' + ext
                // path = process.env.REACT_APP_BACKEND_API + '/uploads/' + filename
            }
            const messageData: IMessage = {
                content:message,
                attachment: attachment.data !== undefined ? {
                    name: filename,
                    type: ext,
                    data: attachment.data
                } : null,
                timestamp: new Date().getTime(),
                author
            }
            chatDispatch(addMessageAction(messageData))
            ws.emit("send-message",{roomId:roomId,message:messageData,conversation_id:conversation_id, attachment:attachment})
            ws.emit("get-conversations",{user_id:conversation_id.user_id})
        }
    }

    const sendFileCamera = (message:string, roomId:string, author:string, conversation_id:any, attachment: any) => {
        if (attachment){
            sendMessage(message, roomId, author, conversation_id, {
                name: attachment.name,
                data: attachment.data
            })
        }
    }

    const sendFileCameraVideo = (message:string, roomId:string, author:string, conversation_id:any, attachment: any) => {
        if (attachment){
            const reader = new FileReader();
            reader.readAsArrayBuffer(attachment.data);
            reader.onload = () => {
                sendMessage(message, roomId, author, conversation_id, {
                    name: attachment.name,
                    data: reader.result
                })
            }
        }
    }

    const sendFile = (message:string, roomId:string, author:string, conversation_id:any, attachment: any) => {
        if (attachment) {
            // if (attachment.target.files[0].size <= 512000){
            if (attachment.target.files[0].size <= 10485760){
                // if (attachment.target.files[0].type === 'video/mp4') {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(attachment.target.files[0]);
                    reader.onload = () => {
                        sendMessage(message, roomId, author, conversation_id, {
                            name: attachment.target.files[0].name,
                            data: reader.result
                        })
                    }
                // } else {
                    // const reader = new FileReader();
                    // reader.readAsDataURL(attachment.target.files[0]);
                    // reader.onload = () => {
                    //     sendMessage(message, roomId, author, conversation_id, {
                    //         name: attachment.target.files[0].name,
                    //         data: reader.result
                    //     })
                    // }
                // }
            } else {
                alert('File is too large for upload.')
            }
        }
    }

    const addMessage = (message:IMessage) =>{
        const ChatContainer1 = document.getElementById('chat-container')
        setTimeout(() => {
            ChatContainer1?.scrollTo({
                top: ChatContainer1?.scrollHeight,
                behavior: "smooth"
              });
        }, 300);
        chatDispatch(addMessageAction(message))
    }

    const addHistory = (messages:IMessage[]) => {
        const ChatContainer2 = document.getElementById('chat-container')
        chatDispatch(addHistoryAction(messages))
        setTimeout(() => {
            ChatContainer2?.scrollTo({
                top: ChatContainer2?.scrollHeight,
              });
        }, 1);
    }

    const toggleChat = () =>{
        chatDispatch(toggleChatAction(!chats.isChatOpen))
    }

    const joinRoom = (channelId:string,userId:string, userName:string, conversation:any) => {
        localStorage.setItem('channel_id', channelId)
        if (conversation.id && conversation.user_id && conversation.participants){
            const conversation_id = localStorage.getItem('conversation_id')
            const conversation_user_id = localStorage.getItem('conversation_user_id')
            const conversation_participants = localStorage.getItem('conversation_participants')
            if (conversation_id !== conversation.id){
                localStorage.setItem('conversation_id', conversation.id)
            }
            if (!conversation_user_id !== conversation.user_id){
                localStorage.setItem('conversation_user_id', conversation.user_id)
            }
            if (!conversation_participants !== conversation.participants){
                localStorage.setItem('conversation_participants', conversation.participants)
            }
            setTargetUserId(channelId)
            setConversationId(conversation)
            setDisableChat(false)
            ws.emit("join-room",{ roomId : channelId, peerId:userId, userName })
        } else {
            setDisableChat(true)
        }
    }

    const createNewChat = ()=>{
        ws.emit("get-users")
        console.log(ws.emit("get-users"))
        setNewChat(!newChat)
    }

    const fetchUsers = (users:any) =>{
        usersDispatch(addUsersAction(users.resources))
    }

    const newConversation = (user:any,senderUser:number) => {
        setNewChat(!newChat)
        // const checkConversation = collect(conversations).where()
        ws.emit("create-conversation",{participant:user.id, user_id:senderUser})
    }

    const conversationCreated = (data:any) =>{
        if(user){
            ws.emit("get-conversations",{user_id:user.id})
            joinRoom(data.conversation.channel_id,data.userdata.username,data.userdata.username,data.conversation)
        }
    }
    
    const broadcastMessage = (data:any) => {
        setBroadcast(data.broadcastMessage)
    }

    useEffect(()=>{
        ws.on("data-fetched", fetchConversations)
        ws.on("room-checked", (roomStat) => setRoomStatus(roomStat))
        ws.on("get-messages", addHistory)
        ws.on("add-message", addMessage)
        ws.on("users-fetched",fetchUsers)
        ws.on("conversation-created",conversationCreated)
        ws.on("broadcast-message", broadcastMessage)
        return ()=>{
            ws.off("data-fetched")
            ws.off("room-checked")
            ws.off("get-messages")
            ws.off("add-message")
            ws.off("users-fetched")
            ws.off("conversation-created")
            ws.off("broadcast-message")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <ChatContext.Provider value={{sendFileCameraVideo, sendFile, sendFileCamera, toggleChat,sendMessage,conversations,chats,roomStatus,joinRoom,targetUserId,conversationId,newChat,createNewChat,users,newConversation,disableChat, broadcast}}>{children}</ChatContext.Provider>
    )
}