import classNames from "classnames";
import { useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
// import { ws } from '../ws';
// import { RoomContext } from "../context/RoomContext";
// import collect from 'collect.js';

export const NewChatSidebar: React.FC<{ open:boolean,onClick: () => void}> = ({open,onClick}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const {users,newConversation} = useContext(ChatContext) 
    const [searchResults, setSearchResults] = useState([]);
    const {user} = useContext(UserContext)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(e.target.value);
    
        // Perform the search logic
        const filteredResults = users.users.filter((user:any) =>
            user.name.toLowerCase().includes(value.toLowerCase())
        );
        setSearchResults(filteredResults);
      };
    return (
        <>
            <div className="relative z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div className={
                classNames(" inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-in-out duration-500",{
                    "fixed":open,
                    "hidden":!open
                })
            }></div>
                <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                    <div className={
                                        classNames("pointer-events-auto relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700",{
                                            "-translate-x-0":open,
                                            "-translate-x-full":!open
                                        })
                                    }>
                        <div className={
                                        classNames("absolute right-0 top-0 -ml-8 flex pr-2 pt-4 sm:-mr-10 sm:pl-4 ease-in-out duration-500",{
                                            "opacity-100":open,
                                            "opacity-0":!open
                                        })
                                    }>
                            <button type="button" className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={onClick}>
                            <span className="sr-only">Close panel</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            </button>
                        </div>

                        <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                            <div className="px-4 sm:px-6 ">
                                <h2 className="text-2xl font-semibold text-gray-900 border-b-2 pb-5 border-black" id="slide-over-title">New Chat</h2>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                <input
                                    type="text"
                                    className="border-b border-gray-300 p-2 mb-4 w-full bg-white outline-none"
                                    placeholder="Search users..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <div className="flex flex-col">
                                    {searchResults.length > 0 ? (
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {searchResults.map((userSearch:any, index: number) => (
                                            <li className="px-2 py-3 sm:py-4 capitalize cursor-pointer hover:bg-slate-200" key={index} onClick={e=>newConversation( userSearch,user.id )}>{userSearch.name}</li>
                                            // <li className="px-2 py-3 sm:py-4 capitalize cursor-pointer hover:bg-slate-200" key={user.id} onClick={e=>newConversation( userData,user.id )}>{user.name}</li>
                                        ))}
                                        </ul>
                                    ) :( searchResults.length === 0 && searchTerm !== '')? (
                                        <p>No results found</p>
                                    ) : (
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {users.users.map((userData:any) => (
                                            <li className="bubble-list shadow-bubble rounded-full px-4 py-3 sm:py-4 capitalize cursor-pointer hover:bg-slate-200" key={userData.id} onClick={e=>newConversation( userData,user.id )}>{userData.name}</li>
                                        ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}