import { createContext, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid"


interface Props {
    initialUser?: any;
    children: React.ReactNode;
}

interface UserValue {
    userId:string
    userName:string
    setUsername: (userName:string)=>void
    setUserData: (data:any)=>void,
    setUserId: (id:string)=>void,
    user:any
}

export const UserContext = createContext<UserValue>({
    userId:"",
    userName:"",
    setUsername: (userName) => {},
    setUserData: (data) => {},
    setUserId: (id) => {},
    user:{}
});


export const UserProvider : React.FC<Props> = ({initialUser,children})=>{
    // const [userId,setUserId] = useState(localStorage.getItem("userId")|| uuidV4())
    const [userId,setUserId] = useState(uuidV4())
    const [userName, setUsername] = useState("userName");
    const [user, setUser] = useState(initialUser || {});
    // const [userName, setUsername] = useState(localStorage.getItem("userName")||"");
    
    // useEffect(()=>{
    //     const generatedUuid = uuidV4();
    //     console.log(generatedUuid)
    //     setUserId(generatedUuid)
    // },[])

    const setUserData = (data:any) =>{
        setUser(data)
    }

    useEffect(()=>{
        localStorage.setItem("userName",userName)
    },[userName])
    useEffect(()=>{
        localStorage.setItem("userId",userId)
    },[userId])
    return <UserContext.Provider value={{userId,userName,setUserId,setUsername,setUserData,user}}>{children}</UserContext.Provider>
}