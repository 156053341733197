import { useContext, useEffect } from "react"
import { UserContext } from "../context/UserContext"
import { ChatContext } from "../context/ChatContext";
import { ws } from "../ws";
import collect from "collect.js";
import classNames from "classnames";

export const ChatList: React.FC<{conversations:Record<string,any>}> = ({conversations}) => {
    const {user} = useContext(UserContext)
    const {joinRoom} = useContext(ChatContext) 
    const channel_id = localStorage.getItem('channel_id') ? localStorage.getItem('channel_id') : null

    useEffect(()=>{
        const checkConversation  = (conversationId:number) =>{
            const checkData = collect(conversations).where('id',conversationId).first()
            if(!checkData){
                ws.emit("get-conversations",{user_id:user.id})
            }
        }
        ws.on("check-conversation",checkConversation)
            return ()=>{
                ws.off("check-conversation")
            }
    },[conversations, user.id])

    return <>
        {conversations.conversations.map((conversation:any,implement:number) => (
            <div key={conversation.id}>
                <button className={
                        classNames("flex flex-col md:flex-row items-center w-full py-2 md:px-3 transition-colors duration-200 gap-x-2  focus:outline-none",{
                            "hover:bg-sky-300":implement % 2 === 0,
                            "hover:bg-green-300":implement % 2 === 1,
                            "bg-primary":conversation.channel_id === channel_id,

                        })
                    } onClick={e => joinRoom(conversation.channel_id,user.username,user.username,conversation)}>
                    <div className={
                        classNames("flex justify-center items-center rounded-full text-white bg-primary md:py-5 px-5 w-12 h-12",{
                            "bg-primary":implement % 2 === 0,
                            "bg-secondary":implement % 2 === 1
                        })
                    }>
                        <span className="relative">{conversation.initials ? conversation.initials : 'NN'}</span>
                    </div>
                
                    <div className="w-full text-center justify-center md:text-left flex md:justify-between md:items-center">
                        <div>
                            <h1 className="md:ml-2 text-sm font-medium text-black capitalize dark:text-white hover:text-white" style={{ color: "black" }}>{conversation.recipient ? conversation.recipient : "no-name" }</h1>
                            {(conversation.message_type_id === 2 || !conversation.message.attachment) ? (
                                <p className="md:ml-2 text-xs font-normal text-slate-100 capitalize dark:text-white hover:text-white" style={{ color: "grey" }}>{conversation.message_type_id === 2 ? conversation.message.content : ""}</p>
                            ) : (
                                <div>
                                {(conversation.message.attachment.type === 'png' || conversation.message.attachment.type === 'jpeg' || conversation.message.attachment.type === 'jpg' || conversation.message.attachment.type === 'webp' ) ? (
                                    <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-card-image w-3 h-3 my-1" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                                    </svg>
                                    {conversation.message.attachment.name ? conversation.message.attachment.name : 'image'}
                                </p>
                                ) : (
                                    <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-file-earmark-text-fill  w-3 h-3 my-1" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                    </svg>
                                    {conversation.message.attachment.name ? conversation.message.attachment.name : 'file'}
                                    </p>
                                )}
                                </div>
                            )}
                        </div>
                        <div className="hidden lg:block">
                            <p className="md:ml-2 text-[10px] text-right font-normal text-grey capitalize dark:text-white hover:text-white" style={{ color: "grey" }}>{conversation.timestamp ? new Date(conversation.timestamp).toLocaleTimeString() : ''}</p>
                        </div>
                    </div>
                </button>
            </div>
        ))}
    </> 
}