import { useContext, useEffect } from "react";
import { ChatContext } from "../context/ChatContext";
import { ws } from '../ws';
// import collect from 'collect.js'
//import component
import { ChatInput } from "../components/ChatInput";
import { ChatBubble } from "../components/ChatBubble";
import { IMessage } from "../types/chat";
import { UserContext } from "../context/UserContext";
import { ChatList } from "../components/ChatList";
import { NewChatButton } from "../components/NewChatButton";
import { NewChatSidebar } from "../components/NewChatSidebar";
import { saveAs } from 'file-saver';
//import assset
import mathMavensLogo from "../assets/images/math-mavens-logo.png"
import chatIcon from "../assets/images/chat.png"
import userIcon from "../assets/images/user.webp"

import classNames from "classnames";


const Chat = () =>{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {conversations,roomStatus,chats,conversationId,newChat,createNewChat,disableChat, joinRoom, broadcast} = useContext(ChatContext) 
    const {user} = useContext(UserContext)
    const channel_id = localStorage.getItem('channel_id') ? localStorage.getItem('channel_id') : null
    const conversation_id = localStorage.getItem('conversation_id') ? localStorage.getItem('conversation_id') : null
    const conversation_user_id = localStorage.getItem('conversation_user_id') ? localStorage.getItem('conversation_user_id') : null
    const conversation_participants = localStorage.getItem('conversation_participants') ?  localStorage.getItem('conversation_participants') : null
    
    useEffect(()=>{
        ws.emit("get-conversations",{user_id:user.id})
        if (channel_id && conversation_id && conversation_user_id && conversation_participants) {
            joinRoom(channel_id,user.id,user.username,
                {
                    id: conversation_id,
                    user_id: conversation_user_id,
                    participants: conversation_participants
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[channel_id, roomStatus, user.id])

    const logout = () => {
        localStorage.clear()
        window.location.href="/"
    }

    const download = (url:any, filename:any) => {
        // var element = document.createElement("a");
        // var file = new Blob(
        //   [
        //     url
        //   ],
        //   { type: "image/*" }
        // );
        // element.href = URL.createObjectURL(file);
        // element.download = filename;
        // element.click();
        let urlData = url
        saveAs(urlData, filename);
      };

    return <>
        <div className="flex flex-col min-h-screen">
            <div className="py-[15px] px-4 bg-primary text-white absolute w-full capitalize flex justify-between items-center">
                <div className="flex justify-start items-end">
                    <img className='w-8 h-full' src={mathMavensLogo} alt="Math_Mavens_logo" />
                    <p className="ml-1 text-primary text-center not-italic font-semibold text-md">
                    Chats App
                    </p>
                </div>
                <div onClick={logout} className="cursor-pointer flex justify-center items-center">
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right fill-white" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                    </svg>
                    </div>
                    <p className="mx-2 text-white">Logout</p>
                </div>
        </div>
            <div className='flex h-screen pt-14'>
                <div className="border-0 w-3/12 md:h-[88%] xl:h-[89%] 2xl:h-[94%] overflow-y-auto">
                    <div className="flex flex-col h-full" data-testid="chat">
                       <ChatList conversations={conversations}/>
                    </div>
                    <div className="flex justify-end items-center p-2 fixed bottom-0 bg-primary w-full h-[80px] md:w-full md:h-[80px]">
                        {/* <button onClick={logout} type = "button" className="shadow-main button-logout button-primary flex justify-center items-center my-2 ml-2 p-3 rounded-full text-white uppercase font font-semibold w-12 h-12" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                            </svg>
                        </button> */}
                    </div>
                </div>

                <div className="flex flex-col w-full relative">
                    <div className="flex border h-full">
                        <div
                            className={classNames("flex h-[90%] w-full flex-col overflow-y-auto flex-grow-0 p-1",{
                                "bg-slate-100":disableChat,
                                "bg-white":!disableChat
                            })} id="chat-container">
                            {(chats.messages.length === 0 && !disableChat) ? (
                                <div className="mx-auto mt-52 flex-col justify-center font-semibold h-full items-center">
                                    <img className="w-16 h-16 object-contain ml-4" src={chatIcon} alt="chat" />
                                    <p>No messages</p>
                                </div>
                            ) : (
                                chats.messages.map((message: IMessage, index: number) => (
                                    <ChatBubble message={message} key={message.timestamp + (message?.author||'anonymous') + index} />
                                ))
                            )}
                        </div>
                        {
                            !disableChat && (
                                <div className="border w-4/12 h-full p-2 options-sidebar">
                                    {/* option */}
                                    <div className="flex justify-center items-center p-4">
                                        <img className='w-1/2 h-full' src={userIcon} alt="user_icon" />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        {/* <div className="rounded-xl w-3 h-3 bg-green-400 "></div> */}
                                        <p className="uppercase text-md font-bold ml-2 text-slate-600">
                                            {conversationId.initials ? conversationId.initials : ''}
                                        </p>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <p className="text-xs font-normal ml-2 text-slate-600">
                                            {conversationId.channel_id ? conversationId.channel_id : ''}
                                        </p>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <p className="capitalize text-md font-semibold ml-2 text-slate-600">
                                            {conversationId.recipient ? conversationId.recipient : ''}
                                        </p>
                                    </div>
                                    {/* <div onClick={createNewChat} className="shadow-main cursor-pointer flex justify-center items-center mt-12 p-4 bg-primary w-36 h-8 mx-auto rounded-full">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex fill-white" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z"/>
                                            </svg>
                                        </div>
                                        <p className="mx-2 text-white">Contact</p>
                                    </div>
                                    <div onClick={logout} className="shadow-main cursor-pointer flex justify-center items-center mt-4 p-4 bg-primary w-36 h-8 mx-auto rounded-full">
                                        <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right fill-white" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                        </svg>
                                        </div>
                                        <p className="mx-2 text-white">Logout</p>
                                    </div> */}
                                    <div className="mt-4 p-4 overflow-y-auto h-[350px] w-full">
                                        {
                                            (chats.messages.length !== 0 && !disableChat) && (
                                                <p className="text-md font-bold text-slate-600">Galery</p>
                                            )
                                        }
                                        {
                                            (chats.messages.length !== 0 && !disableChat) && (
                                                <div className="flex flex-wrap">
                                                    {
                                                        chats.messages.map((message: IMessage, index: number) => (
                                                            <div key={index}>
                                                                { (message.attachment) && 
                                                                    <div className="bg-white w-16 h-16 flex justify-center items-center m-2 p-3 shadow-main rounded-sm">
                                                                        {(message.attachment.type === 'jpeg' || message.attachment.type === 'png' || message.attachment.type === 'webp') ? (
                                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                            <a onClick={() => download(process.env.REACT_APP_BACKEND_API + '/uploads/' + message.attachment.name, message.attachment.name)} rel="noreferrer" className="flex items-center justify-center cursor-pointer" >
                                                                                <img className="object-contain" src={process.env.REACT_APP_BACKEND_API + '/uploads/' + message.attachment.name} alt={message.attachment.name} />
                                                                            </a>
                                                                        ) : (
                                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                            <a onClick={() => download(process.env.REACT_APP_BACKEND_API + '/uploads/' + message.attachment.name, message.attachment.name)} rel="noreferrer" className="flex items-center justify-center cursor-pointer">
                                                                                <div className="object-contain">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down-fill w-full h-full fill-sky-300" viewBox="0 0 16 16">
                                                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <p className="text-[6px]">
                                                                                    {
                                                                                        message.attachment.name
                                                                                    }
                                                                                </p>
                                                                            </a>
                                                                        
                                                                        )
                                                                    }
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <ChatInput conversation_id={conversationId} disabled={disableChat}/>
                </div>
            </div>
            <NewChatButton onClick={createNewChat} />
            <NewChatSidebar open={newChat} onClick={createNewChat} />
        </div>
    </>
}

export default Chat;