// import { IMessage } from "../types/chat";
import { GET_CONVERSATIONS } from "./conversationActions";

export type ConversationState = {
    // messages:IMessage[],
    conversations:string[],
    // isChatOpen: boolean
};
type ConversationAction = 
    // | {
    //     type: typeof ADD_MESSAGE;
    //     payload: { message: IMessage };
    //   } 
    | {
        type: typeof GET_CONVERSATIONS;
        payload: { history: [] };
      }
    // | {
    //     type: typeof TOGGLE_CHAT;
    //     payload: { isOpen: boolean };
    // }

export const conversationReducer = (state:ConversationState, action:ConversationAction)=> {
    switch (action.type){
        // case ADD_MESSAGE:
        //     return {
        //         ...state,
        //         messages: [...state.messages, action.payload.message]
        //     };
        case GET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload.history
            }
        // case TOGGLE_CHAT:
        //     return {
        //         ...state,
        //         isChatOpen: action.payload.isOpen
        //     }
        default:
            return {...state};
    }
}